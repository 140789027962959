import * as React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

import { GatsbyImage, getImage } from 'gatsby-plugin-image'

const allItems = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const posts = data.allMarkdownRemark.nodes
  const versionInfo = data.site.siteMetadata.versionInfo 

  console.log (`VERSION_INFO: ${versionInfo}` )
  
  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="All artwork" />
      <div className="main-content">
        <div className="wrapper">
          <div className="gridBlock">
              {posts.map(post => {
                const title = post.frontmatter.title || post.fields.slug
                const image = getImage(post.frontmatter.main_image)

                return (
                  <div key={title} className="blockElement threeByGridBlock">
                    <article className="post-list-item">
                      <Link style={{textDecoration: 'none'}} to={post.fields.slug}>                
                        <span style={{marginBottom: '5px', fontSize: '1.2em', display:'block'}}>
                          {title}
                        </span>                       
                        <GatsbyImage image={image} alt={post.frontmatter.main_image_alt} />
                      </Link>                      
                     </article>
                  </div>
                )
              })}
            </div>  
          </div>    
        </div>
	
<div class="contact-bottom">
<h2>Contact me:</h2>
<a href="mailto:lenamuzyka777@gmail.com">lenamuzyka777@gmail.com</a>
	</div> 	
    </Layout>
  )
}

export default allItems

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        versionInfo
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___webpage_order]  }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
          category
          main_image_alt
          main_image{
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`
